import {
  CongressSelector, Header, RegistrationForm, RequestToken, ValidateToken, ContactForm, Summary, Finish
} from "./components"
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { createTheme, ThemeProvider } from "@fluentui/react/lib/Theme";
import { useMainState } from "./globalState/context";
import "./App.css";
import { Link, Stack } from "@fluentui/react";

initializeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/");

const theme = createTheme({
  palette: {
    themePrimary: "#0063be",
    themeLighterAlt: "#f2f8fc",
    themeLighter: "#cee2f5",
    themeLight: "#a5caec",
    themeTertiary: "#579ad9",
    themeSecondary: "#1872c7",
    themeDarkAlt: "#0059ac",
    themeDark: "#004b91",
    themeDarker: "#00376b",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff"
  }
});

const App = () => {
  const { state: { tokenSent, validated, selectedCongress, screen } } = useMainState();

  return (
    <div className="mainContainer">
      <div className="container">
        <ThemeProvider theme={theme}>
          <div className="header">
            <Header />
          </div>
          <div className="main">
            {!tokenSent && <RequestToken />}
            {tokenSent && !validated && <ValidateToken />}
            {validated && screen === "regForm" && <CongressSelector />}
            {selectedCongress && screen === "regForm" && <RegistrationForm />}
            {screen === "contactForm" && <ContactForm />}
            {screen === "summary" && <Summary />}
            {screen === "finish" && <Finish />}
          </div>
        </ThemeProvider>
      </div>
      <Stack tokens={{ childrenGap: 3 }}>
        <small><Link href="mailto:compliance-czsk@fresenius-kabi.com?subject=Registrace na akci">compliance-czsk@fresenius-kabi.com</Link></small>
        <small>&copy; {new Date().getFullYear()} Fresenius Kabi s.r.o.</small>
      </Stack>
    </div>
  );
}

export default App;
